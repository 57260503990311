import React from 'react';
import { Menu } from 'semantic-ui-react';
import ContextItem from './Context.item';
import PropTypes from 'prop-types';

const ContextList = ({ elementList, currentDataID, timestamp }) => {

  return (
    <Menu vertical style={{ width: '100%' }}>
      {
        elementList.map((element, index) => (
          <ContextItem
            key={`key-${index}`}
            context={element}
            currentDataID={currentDataID}
            timestamp={timestamp}
          />
        ))}
    </Menu>
  );
};

ContextList.propTypes = {
  elementList: PropTypes.array,
  currentDataID: PropTypes.any,
  timestamp: PropTypes.any,
};

export default ContextList;