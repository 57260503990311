import React, { useState, useEffect } from 'react';
import Graph from './Graph.widget';
import { getGraphData } from '../../api/query/static/run.style';
import PropTypes from 'prop-types';
import { toast } from '../../utils/notification';

const GraphWidget = ({ navData, actionsState }) => {
  const [graphRenderData, setGraphRenderData] = useState(null);
  const [graphData, setGraphData] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setGraphRenderData(navData.widgetData);
        const response = await getGraphData({ ...actionsState, widgetKey: navData.renderID }, navData.widgetData);
        setGraphData(response.objects);
      } catch (error) {
        toast.error('Error fetching graph data:', error.message);
        throw error;
      }
    };

    fetchData();
    return () => {
      setGraphData(null);
    };
  }, [navData.renderID, navData.widgetData.dataID, navData.widgetData.timestamp]);


  if (!graphData || !graphRenderData) return null;

  return (
    <>
      <Graph
        actionsState={actionsState}
        navData={navData}
        renderData={graphRenderData}
        graphData={graphData}
      />
    </>
  );
};

GraphWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GraphWidget;
