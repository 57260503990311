import {
  SET_HEART_UPDATE_WIDGET_DATA
} from './types';
  
  
export const setUpdateWidgetData = (data : object) => {
  return {
    type: SET_HEART_UPDATE_WIDGET_DATA,
    data     
  };
};

  