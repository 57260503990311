// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-container {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden !important;
    margin-top: 10px;
    scroll-behavior: smooth;
  }

  .body-message {
    text-align: left;
    margin-left: 15px;
    display: flex !important;
  }
  
  .body-message-edit {
    border: 1px solid rgb(207, 207, 207);
    border-radius: 3px;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .body-message-datetime {
    text-align: right;
    margin-left: 5px;
    margin-top: 10px;
    font-size: 10px;
    width: 100px;
    visibility: hidden;
  }
  
  .body-message-text {
    border: none !important;
    max-width: 90% !important;
  }
  
  .body-message-datetime:hover {
    visibility: visible !important;
  }
  
  .body-message:hover .body-message-datetime {
    visibility: visible !important;
  }

  .body-history {
    margin-top: 20px !important;
  }
  
  .body-message-header {
    display: flex !important;
    width: 300px;
  }
  
  .body-message-sender {
    max-width: 700px;
    margin: 5px 0px 5px 10px;
    border-radius: 10px;
    font-weight: bold;
    color: rgb(181, 181, 181);
  }
  `, "",{"version":3,"sources":["webpack://./src/widgets/chat/body/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,gBAAgB;IAChB,6BAA6B;IAC7B,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;EAC1B;;EAEA;IACE,oCAAoC;IACpC,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;EAC3B;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;IACxB,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,wBAAwB;IACxB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;EAC3B","sourcesContent":[".body-container {\n    width: 100%;\n    background-color: #fff;\n    padding: 20px;\n    overflow-y: auto;\n    overflow-x: hidden !important;\n    margin-top: 10px;\n    scroll-behavior: smooth;\n  }\n\n  .body-message {\n    text-align: left;\n    margin-left: 15px;\n    display: flex !important;\n  }\n  \n  .body-message-edit {\n    border: 1px solid rgb(207, 207, 207);\n    border-radius: 3px;\n    margin-top: 10px;\n    margin-left: 10px;\n  }\n  \n  .body-message-datetime {\n    text-align: right;\n    margin-left: 5px;\n    margin-top: 10px;\n    font-size: 10px;\n    width: 100px;\n    visibility: hidden;\n  }\n  \n  .body-message-text {\n    border: none !important;\n    max-width: 90% !important;\n  }\n  \n  .body-message-datetime:hover {\n    visibility: visible !important;\n  }\n  \n  .body-message:hover .body-message-datetime {\n    visibility: visible !important;\n  }\n\n  .body-history {\n    margin-top: 20px !important;\n  }\n  \n  .body-message-header {\n    display: flex !important;\n    width: 300px;\n  }\n  \n  .body-message-sender {\n    max-width: 700px;\n    margin: 5px 0px 5px 10px;\n    border-radius: 10px;\n    font-weight: bold;\n    color: rgb(181, 181, 181);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
