import React, { useState, useEffect } from 'react';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Inject,
  TimelineViews,
  Resize,
  DragAndDrop,
  TimelineMonth,
  Day,
  TimelineYear,
  Print,
  ExcelExport,
  ICalendarImport,
  ICalendarExport,
  Month,
  Year,
  Agenda,
  WorkWeek,
  Week
} from '@syncfusion/ej2-react-schedule';
import { withTranslation } from 'react-i18next';
import SchedulerToolbar from './Scheduler.toolbar';
import { runStaticQuery } from '../../api/query/run.staticQuery';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import './styles.css';
import PropTypes from 'prop-types';
import { WidgetContainerStyled, WidgetLabelStyled } from '../styles';
import { toast } from '../../utils/notification';

const Scheduler = ({ navData, t, actionsState }) => {
  const [schedulerData, setSchedulerData] = useState(null);
  const [scheduleObj, setScheduleObj] = useState(null);
  const [selectedDate] = useState(new Date());
  const isTimelineView = false;
  const fieldsData = {
    id: 'id',
    subject: { name: 'name', title: 'Name' },
    location: { name: 'location', title: 'Location' },
    description: { name: 'description', title: 'Description' },
    startTime: { name: 'start', title: 'Start' },
    endTime: { name: 'end', title: 'End' },
    recurrenceRule: { name: 'recurrenceRule' },
    isAllDay: { name: 'isAllDay' }
  };


  useEffect(() => {
    runDynamicQuery({ ...actionsState, params: actionsState, ...actionsState?.response, widgetKey: navData.renderID }, navData.widgetData)
      .then((response) => {
        setSchedulerData(response.targets);
      });
  }, []);

  const actionComplete = async (args) => {
    const { requestType, addedRecords, deletedRecords, changedRecords } = args;

    const procedures = {
      eventCreated: 'createIncident',
      eventChanged: 'updateIncident',
      eventRemoved: 'deleteIncident',
    };

    const eventKey = navData.widgetData?.events?.find(event => event.type === 'onClick')?.key;

    if (!scheduleObj || !['eventCreated', 'eventChanged', 'eventRemoved'].includes(requestType)) {
      return null;
    }

    try {
      const procedureName = procedures[requestType];
      let records;

      switch (requestType) {
        case 'eventCreated':
          records = addedRecords;
          break;
        case 'eventChanged':
          records = changedRecords;
          break;
        case 'eventRemoved':
          records = deletedRecords;
          break;
        default:
          toast.error('', 'Unknown requestType');
          return null;
      }

      const response = await runStaticQuery(procedureName, { eventData: records[0], ...actionsState });
      const id = response.id || records[0].id;
      const foundEvent = scheduleObj.getCurrentViewEvents().find(event => event.id === records[0].id);

      if (foundEvent) {
        foundEvent.id = id;
        scheduleObj.refreshEvents();
      }
      return runActions(eventKey, id, actionsState);
    } catch (error) {
      toast.error(`Error at ${procedures[requestType]}`, error.message);
      return null;
    }
  };


  const onEventClick = (args) => {
    const eventKey = navData.widgetData?.events?.find((event) => event.type === 'onClick')?.key;
    if (eventKey) {
      runActions(eventKey, args.event.id, actionsState);
    }
  };

  const onCellClick = () => {
    const eventKey = navData.widgetData?.events?.find((event) => event.type === 'onClick')?.key;
    if (eventKey) {
      runActions(eventKey, null, actionsState);
    }
  };

  if (!navData?.widgetData || !schedulerData)
    return null;

  return (
    <WidgetContainerStyled>
      {
        navData.widgetData.label !== '' ? <WidgetLabelStyled>{t(navData.widgetData.label)} </WidgetLabelStyled> : ''
      }

      <SchedulerToolbar
        scheduleObj={scheduleObj}
        isTimelineView={isTimelineView}
      />
      <ScheduleComponent
        locale={JSON.parse(localStorage.getItem('language'))}
        ref={ref => setScheduleObj(ref)}
        selectedDate={selectedDate}
        height={'100%'}
        eventSettings={{ dataSource: schedulerData, fields: fieldsData }}
        actionComplete={actionComplete}
        eventClick={onEventClick}
        cellClick={onCellClick}
        showQuickInfo={false}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="WorkWeek" />
          <ViewDirective option="Month" />
          <ViewDirective option="Year" />
          <ViewDirective option="Agenda" />
          <ViewDirective option="TimelineDay" />
          <ViewDirective option="TimelineWeek" />
          <ViewDirective option="TimelineWorkWeek" />
          <ViewDirective option="TimelineMonth" />
          <ViewDirective option="TimelineYear" />
        </ViewsDirective>
        <Inject
          services={[
            Day,
            Week,
            WorkWeek,
            Month,
            Year,
            Agenda,
            TimelineViews,
            TimelineMonth,
            TimelineYear,
            DragAndDrop,
            Resize,
            Print,
            ExcelExport,
            ICalendarImport,
            ICalendarExport
          ]}
        />
      </ScheduleComponent>
    </WidgetContainerStyled>
  );
};

Scheduler.propTypes = {
  navData: PropTypes.object,
  t: PropTypes.func,
  actionsState: PropTypes.object,
};

export default withTranslation()(Scheduler);
