// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  
  /* TODO: check to form css */
  .dynamicColumn {
    padding: 5px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    height: 100%;
  }

  .main-content {
    height: calc(100vh - 96px);
  }
  
  .workspace-star-icon {
    color: rgb(232, 212, 2) !important;
  }


  .policy-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px; 
    color: rgb(137, 137, 137);

    &.e-icons {
      padding: 4px;
      border-radius: 50%;
      font-size: 10px;

      &:hover {
        background-color: #C2C2C2;
      }
    }
  }

  .policy-button-container {
    display: flex !important;
    justify-content: center !important;
    gap: 20px !important;
  }
  
`, "",{"version":3,"sources":["webpack://./src/pages/module/workspaces/styles.css"],"names":[],"mappings":";EACE,4BAA4B;EAC5B;IACE,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kCAAkC;EACpC;;;EAGA;IACE,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,yBAAyB;;IAEzB;MACE,YAAY;MACZ,kBAAkB;MAClB,eAAe;;MAEf;QACE,yBAAyB;MAC3B;IACF;EACF;;EAEA;IACE,wBAAwB;IACxB,kCAAkC;IAClC,oBAAoB;EACtB","sourcesContent":["  \n  /* TODO: check to form css */\n  .dynamicColumn {\n    padding: 5px;\n    display: inline-block;\n    vertical-align: top;\n    border-radius: 3px;\n    height: 100%;\n  }\n\n  .main-content {\n    height: calc(100vh - 96px);\n  }\n  \n  .workspace-star-icon {\n    color: rgb(232, 212, 2) !important;\n  }\n\n\n  .policy-button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-size: 12px; \n    color: rgb(137, 137, 137);\n\n    &.e-icons {\n      padding: 4px;\n      border-radius: 50%;\n      font-size: 10px;\n\n      &:hover {\n        background-color: #C2C2C2;\n      }\n    }\n  }\n\n  .policy-button-container {\n    display: flex !important;\n    justify-content: center !important;\n    gap: 20px !important;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
