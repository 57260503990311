import { setGridFilterValuesToBreadcrumb } from '../../../store/workspaces/breadcrumb/actions';
import store from '../../../store/store';

export const setFilterValues = async (args, key) => {
  const breadcrumbGridFilterValues = store.getState()?.workspaces?.instances.find(instance => instance.isActive)?.breadcrumb?.currentPage?.grid?.filterValues || [];

  if (args.action === 'clearFilter') {
    const updatedFilterValues = await breadcrumbGridFilterValues.filter(element => element.field !== args.currentFilterObject.properties.field);
    await store.dispatch(setGridFilterValuesToBreadcrumb(updatedFilterValues, key));
  }

  if (args.action === 'filter') {
    const { field, value, uid } = args.currentFilterObject;
    const filterObject = { key, field, value, uid };

    let updatedFilterValues = breadcrumbGridFilterValues;

    if (!updatedFilterValues.some(element => element.field === field)) {
      await updatedFilterValues.push(filterObject);
    } else {
      updatedFilterValues = await breadcrumbGridFilterValues.map(element =>
        element.field === filterObject.field ? filterObject : element
      );
    }

    await store.dispatch(setGridFilterValuesToBreadcrumb(updatedFilterValues, key));
  }
};

export const insertFilterValues = async (instance, navData) => {
  try {
    const currentPageGrid = store.getState().workspaces.instances.find(instance => instance.isActive)?.breadcrumb?.currentPage?.grid;

    if (!currentPageGrid) {
      return;
    }

    const { filterValues, key } = currentPageGrid;

    filterValues?.forEach(element => {
      if (key === navData.widgetData.key) {
        instance.filterModule.filterByColumn(element.field, null, element.value);
      }
    });
  } catch (error) {
    console.log('Grid: Error setting filter value:', error);
  }
};
