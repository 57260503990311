import { addPostsScrollTop } from '../../../store/workspaces/chat/actions';
import { getPosts } from '../../../api/services/chat/post.get';
import store from '../../../store/store';

export const getPostsDataInfiniteScroll = async (posts, targetDivRef) => {
  const params = {
    id: posts[0]?.id,
    limit: 2,
  };

  try {
    const response = await getPosts(params);
    const loadedMessages = response?.message;

    if (loadedMessages.length !== 1) {

      await store.dispatch(addPostsScrollTop([...loadedMessages.slice(0, loadedMessages.length - 1)]));
      await new Promise((resolve) => setTimeout(resolve, 100));

      const currentScrollTop = targetDivRef?.current?.scrollTop;
      const newScrollHeight = targetDivRef?.current?.scrollHeight;
      const minimalScrollTop = Math.min(currentScrollTop + 15, newScrollHeight);

      targetDivRef.current.scrollTop = minimalScrollTop;
    }
  } catch (error) {
    console.log(`Error getting posts: ${error}`);
    throw error;
  }
};
