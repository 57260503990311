// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-icon {
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
}

.filter-input {
  margin-left: 20px !important;
  margin-right: 10px !important;
}

.filter-content {
  white-space: nowrap !important;
  width: 220px;
  height: 29px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.filter-container {
  border: 1px solid rgb(216, 216, 216);
  background-color: white !important;
  border-radius: 3px;
  border-bottom: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/chat/body/filter/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,kCAAkC;EAClC,kBAAkB;EAClB,8BAA8B;AAChC","sourcesContent":[".filter-icon {\n  display: inline-block;\n  cursor: pointer;\n  margin-left: 10px;\n}\n\n.filter-input {\n  margin-left: 20px !important;\n  margin-right: 10px !important;\n}\n\n.filter-content {\n  white-space: nowrap !important;\n  width: 220px;\n  height: 29px;\n  border-radius: 4px;\n  margin-bottom: 8px;\n}\n\n.filter-container {\n  border: 1px solid rgb(216, 216, 216);\n  background-color: white !important;\n  border-radius: 3px;\n  border-bottom: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
