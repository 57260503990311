import React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { withTranslation } from 'react-i18next';
import { FormInputContainerStyled, FormInputLabelContainerStyled, ValidationLabelStyled } from './styles';
import { toast } from '../../../../utils/notification';

class ListField extends React.Component<any, any> {

  private dropdownInstance: DropDownListComponent;
  flag = true;

  onChange = (args: any) => {
    const { setFormEditData, options } = this.props;

    try {
      const findOption = options.find(o => o.id === args?.itemData?.id);
      setFormEditData(findOption);
    } catch (error) {
      toast.error('', error.message);
      throw error;
    }
  };

  render() {
    const { field, isLocked, setFormEditData, options } = this.props;

    let placeholder;
    let initData;

    try {
      initData = options?.find(o => o?.selected === true);
      placeholder = initData ? initData.label : 'Select something';

      if (initData) {
        if (this.flag === true) {
          setTimeout(function () {
            setFormEditData(initData);
          }, 500);
        }
        this.flag = false;
      }
    } catch (error) {
      toast.error('', error.message);
    }

    return (
      <FormInputContainerStyled hidden={+field.hidden} className='listfield'>
        <FormInputLabelContainerStyled>{this.props.t(field.label)}</FormInputLabelContainerStyled>
        <ValidationLabelStyled id={`validation-label-${field.attribute.key}`}></ValidationLabelStyled>
        <div
          className='form-input-element'
          // style={{ backgroundColor: setColorField() }}
        >
          <DropDownListComponent
            dataSource={options}
            fields={{ text: 'label', value: 'label' }}
            ref={(scope) => { (this.dropdownInstance as DropDownListComponent | null) = scope; }}
            className='form-input-element'
            placeholder={placeholder}
            value={initData ? initData?.label : ''}
            change={this.onChange.bind(this)}
            enabled={!isLocked}
          />
        </div>
      </FormInputContainerStyled>
    );
  }
}
export default (withTranslation()(ListField));