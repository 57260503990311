import store from '../../../../store/store';
import { toast } from '../../../../utils/notification';
import { setEventSourceHeartBeat } from '../heartbeat';
import { setUpdateWidgetData } from 'src/store/heartbeat/actions';

export const updateWidgetData = (event) => {
  try {
    const data = JSON.parse(event.data);
    store.dispatch(setUpdateWidgetData( {...data} ));
  } catch (error) {
    toast.error('heartbeat (updateWidgetData)', error.message);
    setTimeout(setEventSourceHeartBeat, 5000);
  }
};