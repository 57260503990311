import React from 'react';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { Menu, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ContextItem = ({ context, currentDataID }) => {
  const { t } = useTranslation();

  const handleIconClick = (element: any, e: any) => {
    const eventApi = element.events?.find(event => event?.type === 'onClick');
    const parameters = { id: currentDataID };
    const { modifiers, key } = eventApi;

    if (!eventApi) return null;


    window.dispatchEvent(
      new CustomEvent(
        'custom-mousedown',
        {
          detail: {
            key: key,
            id: currentDataID,
            modifiers: modifiers,
            event: e,
            context: 'context'
          }
        }
      )
    );

    if (!e.ctrlKey && !modifiers?.ctrl && !modifiers?.alt && !modifiers?.shift) {
      runActions(eventApi?.key, currentDataID, parameters);
    }
  };

  const handleItemClick = (e: any) => {
    const eventApi = context.events.find(event => event.type === 'onClick');
    const parameters = { id: currentDataID };
    const { modifiers, key } = eventApi;

    if (!eventApi) {
      return null;
    }

    window.dispatchEvent(
      new CustomEvent(
        'custom-mousedown',
        {
          detail: {
            key: key,
            id: currentDataID,
            modifiers: modifiers,
            event: e
          }
        }
      )
    );

    if (!e.ctrlKey) {
      runActions(eventApi?.key, currentDataID, parameters);
    }
  };

  return (
    <Menu style={{ width: '100%' }}>
      <Menu.Item
        name={context.label}
        disabled={context.disabled}
        onClick={(e: any) => handleItemClick(e)}
        className={'menu-item-context'}
      >
        {context.icon.name !== '' ? <span className={`e-icons e-${context.icon.name} label-icon`} /> : null}
        {t(context.label)}
      </Menu.Item>
      <Menu.Item position="right">
        {context.menu ? context.menu.elements.map((element) => {
          return (
            <button
              key={`key-${element.id}-random:${Math.random()}`}
              className={`e-control e-btn ${context.disabled ? 'e-disabled' : element.disabled ? 'e-disabled' : ''}`}
              disabled={context.disabled ? true : element.disabled}
              onClick={!element.disabled ? (e: any) => handleIconClick(element, e) : null}
            >
              <span
                className={`e-icons e-${element.icon.name}`}

                style={{ cursor: context.disabled ? 'default' : 'pointer' }}
              />
            </button>
          );
        })
          : null}
        <Label style={{ float: 'right' }} className={`menu-item-counter${context.count === 0 ? ' empty' : ''}`}>
          {context.count}
        </Label>
      </Menu.Item>
    </Menu>
  );
};

ContextItem.propTypes = {
  context: PropTypes.object,
  currentDataID: PropTypes.any,
  timestamp: PropTypes.any
};

export default ContextItem;