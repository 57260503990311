import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React from 'react';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { handleExpandLevel } from './handleExpandLevel';
import { MenubarContainerStyled } from '../../../components/MenuBar/styles';


export const ToolbarTemplate = (navData, instance) => {

  const buttons = [
    { id: 'ExpandAll', content: 'Expand All' },
    { id: 'CollapseAll', content: 'Collapse All' },
    { id: '1', content: 'Level 1' },
    { id: '2', content: 'Level 2' },
    { id: '3', content: 'Level 3' },
  ];

  return (
    <div className='toolbar-container'>
      <MenubarContainerStyled css=''>
        {buttons.map(({ id, content }) => (
          <ButtonComponent
            key={id}
            id={id}
            content={content}
            onClick={(args: any) => handleExpandLevel(instance, args.target.id)}
            cssClass='treegrid-toolbar-button'
          />
        ))}
      </MenubarContainerStyled>
      {navData.widgetData.menu ? (
        <MenuBar
          key={`menu-${navData.widgetData.menu.id}`}
          menu={navData.widgetData.menu}
        />
      ) : null}
    </div>
  );
};