// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-schedule {
    height: 100% !important;
    width: 100% !important;
}

.e-tbar-btn-text {
    text-transform: none !important;
}

.e-appointment {
    background-color: rgb(211, 211, 211) !important;
    color: rgb(0, 0, 0) !important;
}

.e-popup-header {
    background-color: rgb(242, 242, 242) !important;
}

.e-text-ellipsis {
    color: black !important;
}

.e-selected-cell {
    background-color: rgb(237, 237, 237) !important;
}

.e-month-calendar {
    overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/widgets/scheduler/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,+CAA+C;IAC/C,8BAA8B;AAClC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".e-schedule {\n    height: 100% !important;\n    width: 100% !important;\n}\n\n.e-tbar-btn-text {\n    text-transform: none !important;\n}\n\n.e-appointment {\n    background-color: rgb(211, 211, 211) !important;\n    color: rgb(0, 0, 0) !important;\n}\n\n.e-popup-header {\n    background-color: rgb(242, 242, 242) !important;\n}\n\n.e-text-ellipsis {\n    color: black !important;\n}\n\n.e-selected-cell {\n    background-color: rgb(237, 237, 237) !important;\n}\n\n.e-month-calendar {\n    overflow-y: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
