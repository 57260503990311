import React, { useState, useRef, useEffect } from 'react';
import { Grid, Popup, Menu } from 'semantic-ui-react';
import { GridStyled } from '../styles';
import DOMPurify from 'dompurify';
import { run as runActions } from '../../../../store/workspaces/workspaces/run';
import { useTranslation } from 'react-i18next';

const renderField = (field, args) => {
  const fieldValue = args[field.attribute.name];
  return field.type === 'richtext' 
    ? <div key={field.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fieldValue) }} />
    : <div key={field.id}>{fieldValue}</div>;
};

export const renderCardTemplate = (args, navData, actionsState) => {
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const contextMenuRef = useRef(null);
  const { t } = useTranslation();

  const contextMenuItems = navData.widgetData.menu.context.elements.map(element => ({
    key: element.label,
    text: element.label,
  }));

  const handleContextMenu = (e) => {
    e.preventDefault();
    setTimeout(() => {
      setContextMenuVisible(true);
    }, 20);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const popupElement = document.querySelector('.ui.popup'); 
      if ((contextMenuRef.current && contextMenuRef.current.contains(e.target)) || (popupElement && popupElement.contains(e.target))) return;
      setContextMenuVisible(false); 
    };
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [contextMenuRef]);


  const handleMenuClick = (selectedText) => {
    const matchingElement = navData.widgetData.menu.context.elements.find(el => el.label === selectedText);
    const eventKey = matchingElement?.events.find(event => ['onClick', 'onLoad'].includes(event?.type))?.key;  
    runActions(eventKey, navData.widgetData.dataID, { ...actionsState }, null);
    setContextMenuVisible(false);
  };

  const renderContextMenu = () => (
    <Popup
      open={contextMenuVisible}
      onClose={() => setContextMenuVisible(false)}
      context={contextMenuRef}
      position="bottom left"
      offset={[0, -20]} //
    >
      <Menu vertical>
        {contextMenuItems.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={() => handleMenuClick(item.text)}
          >
            {t(item.text)}
          </Menu.Item>
        ))}
      </Menu>
    </Popup>
  );

  return (
    <div
      id="target-kanban-card"
      onContextMenu={handleContextMenu}
      ref={contextMenuRef}
      style={{ position: 'relative', userSelect: 'none' }}
    >
      {renderContextMenu()}
      <GridStyled css={navData.widgetData.style}>
        {navData.widgetData.sections.map(section => (
          <Grid.Row key={section.key} columns={section.fieldSets.length} className={section.class}>
            {section.fieldSets.map(fieldSet => (
              <Grid.Column key={fieldSet.key} className={fieldSet.class}>
                {fieldSet.fields.map(field => (
                  <Grid.Row
                    key={field.key}
                    className={`${field.class}${field.hidden ? ' hide' : ''}`}
                  >
                    {renderField(field, args)}
                  </Grid.Row>
                ))}
              </Grid.Column>
            ))}
          </Grid.Row>
        ))}
      </GridStyled>
    </div>
  );
};
