import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  Inject,
  FormDesigner
} from '@syncfusion/ej2-react-pdfviewer';
import { WidgetProvider } from '../../WidgetContext';
import MenuBar from '../../../components/MenuBar/MenuBar';
import { addSignature, moveSignature, removeSignature, resizeSignature, signaturePropertiesChange } from './events/signatures';
import { formFieldAdd, formFieldMove, formFieldPropertiesChange, formFieldRemove, formFieldResize } from './events/fields';
import { annotationAdd, annotationMove, annotationRemove, annotationResize } from './events/annotations';
import PropTypes from 'prop-types';
import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { getToolbarSettings } from './settings/toolbar';
import { WidgetContainerStyled } from '../../styles';
import request from '../../../request/request';
import { log } from '../../../utils/notification';

const PDFViewer = ({ navData, actionsState }) => {
  const viewerInstance: any = useRef();
  const [signatures, setSignatures] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [fields, setFields] = useState([]);
  const [file, setFile] = useState(null);

  const PDFViewerContext = createContext(
    {
      props: {navData, actionsState},
      viewerInstance,
      formDesignerData:{ signatures, annotations, fields}
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID  }, navData.widgetData);

        if(Object.keys(response).length === 0) {
          if (viewerInstance.current) {
            viewerInstance.current.destroy();
            viewerInstance.current = null;
          }
          setFile(null);
          return null;
        } 

        const { options } = response;
        const resp = await request<Request.Download, any>(true, 'POST', 'services/download',  { files: response[options.data] });
  
        const files = resp.files.map(({ name, uri }) => ({ fileName: name, uri: uri }));
        setFile(files[0]);
      } catch (error) {
        log('PDFViewer fetchData', { params: { ...actionsState, widgetKey: navData.renderID  }, response: { message: error.message } }, 'Error');
      }
    };
    fetchData();

    return () => {
      if (viewerInstance.current) {
        viewerInstance.current.unload();
        viewerInstance.current.destroy();
        viewerInstance.current = null;
      }
      setFile(null);
    };

  }, [navData]);


  const created = () => {
    if (!file && !viewerInstance) {
      return;
    }
    viewerInstance.current.load(file.uri, null);
  };


  if (!file) {
    return null;
  }

  return (
    <WidgetContainerStyled>
      <WidgetProvider value={PDFViewerContext}>
        {
          navData.widgetData.menu
            ? <MenuBar
              key={`menu-${navData.widgetData.menu.id}`}
              menu={navData.widgetData.menu}
            />
            : null
        }
        <PdfViewerComponent
          id="container"
          ref={viewerInstance}
          serviceUrl={`${process.env.REACT_APP_URL}api/pdfviewer`}
          ajaxRequestSettings={{
            ajaxHeaders: [
              {
                headerName: 'Authorization',
                headerValue: 'Bearerabcdefghijklmnopqrstuvwxyz',
              },
            ],
            withCredentials: true,
          }}
          height={'calc(100% - 50px)'}
          toolbarSettings={getToolbarSettings(navData.widgetData.type)}
          enableFormDesignerToolbar={true}
          downloadFileName={file.fileName}
          created={created}
          addSignature={(args) => addSignature(args, setSignatures)}
          moveSignature={(args) => moveSignature(args, setSignatures)}
          removeSignature={(args) => removeSignature(args, setSignatures)}
          resizeSignature={(args) => resizeSignature(args, setSignatures)}
          signaturePropertiesChange={(args) => signaturePropertiesChange(args, setSignatures)}
          annotationAdd={(args) => annotationAdd(args, setAnnotations)}
          annotationMove={(args) => annotationMove(args, setAnnotations)}
          annotationRemove={(args) => annotationRemove(args, setAnnotations)}
          annotationResize={(args) => annotationResize(args, setAnnotations)}
          formFieldAdd={(args) => formFieldAdd(args, setFields)}
          formFieldResize={(args) => formFieldResize(args, setFields)}
          formFieldMove={(args) => formFieldMove(args, setFields)}
          formFieldPropertiesChange={(args) => formFieldPropertiesChange(args, setFields)}
          formFieldRemove={(args) => formFieldRemove(args, setFields)}
        >
          <Inject services={[
            Toolbar,
            Magnification,
            Navigation,
            LinkAnnotation,
            BookmarkView,
            ThumbnailView,
            Print,
            TextSelection,
            TextSearch,
            Annotation,
            FormFields,
            FormDesigner
          ]}
          />
        </PdfViewerComponent>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

PDFViewer.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default PDFViewer;
