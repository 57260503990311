import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from '../../utils/notification';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { setCookie } from '../../utils/cookies';
import './styles.css';
import request from '../../request/request';

const LoginTokenDialog: FC<any> = ({ dialogVisible, username, password, resetState, setDialogVisible, version, authenticationMode }) => {
  const [token, setToken] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const textBoxRef = useRef(null);

  useEffect(() => {
    if (dialogVisible) {
      localStorage.setItem('2k-dialog', JSON.stringify(true));
    }
  }, [dialogVisible]);

  useEffect(() => {
    const handleKeyDown = (e) => e.key === 'Enter' && handleSubmit();
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [token]);
  

  const handleSubmit = async () => {
    if (token === '' || username === '' || password === '') return;

    try {
      const extended = JSON.parse(localStorage.getItem('extended'));
      const response = await request(false, 'POST', 'public/auth/signin', {
        extended,
        authenticationMode,
        username,
        password,
        version,
        token,
      });

      Object.keys(response.data).forEach((key) => localStorage.setItem(key, JSON.stringify(response.data[key])));
      if (rememberMe) setCookie('sessionCookie', 'cookieValue', 3);

      localStorage.removeItem('2k-dialog');
      window.location.href = '/app';
      resetState();
      setToken('');
      setRememberMe(false);
    } catch (error) {
      if (error.response) {
        await request(true, 'POST', 'public/user/logout');
        toast.error('', error.response.data.message);
      }
    }
  };

  const handleCancel = async () => {
    localStorage.removeItem('2k-dialog');
    await request(true, 'POST', 'public/user/logout');
    setDialogVisible(false);
    setToken('');
  };

  const dialogButtons = [
    { click: handleSubmit, buttonModel: { content: 'Confirm'} },
    { click: handleCancel, buttonModel: { content: 'Cancel' } },
  ];

  return (
    <DialogComponent
      visible={dialogVisible}
      width="300px"
      height="200px"
      header="Authentication"
      buttons={dialogButtons}
      showCloseIcon={true}
      close={() => handleCancel()}
    >
      <TextBoxComponent
        ref={textBoxRef}
        type="text"
        name="token"
        className="login-form-input"
        value={token}
        input={(e) => setToken(e.value)}
        placeholder="OTP-Secret"
        floatLabelType="Auto"   
      />

    </DialogComponent>
  );
};

export default LoginTokenDialog;
