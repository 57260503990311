import React, { useEffect, useRef, useState } from 'react';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../components/MenuBar/MenuBar';
import PropTypes from 'prop-types';
import { renderPdf } from '../../api/services/pdf/renderPdf';
import { ViewContainerStyled } from './styles';
import { toast } from '../../utils/notification';
import { useSelector } from 'react-redux';



const base64toBlob = (base64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const Report = ({ navData, actionsState }) => {
  const viewerInstanceRef = useRef(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentWorkspaceUuid, seCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);

  const [report, setReport] = useState(null);
  // const [formEditFiles, setFormEditFiles] = useState(null);

  const widgetContext = {
    // formEditFiles: formEditFiles,
    viewerInstance: viewerInstanceRef.current,
    widgetData: navData.widgetData,
    currentWorkspaceUuid: currentWorkspaceUuid
  };

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    seCurrentWorkspaceUuid(currentWorkspace.uuid);
  }, []); 

  useEffect(() => {
    const getReport = async () => {
      try {
        const params: object = { ...actionsState, widgetKey: navData.renderID };
        const id: number = navData.renderID;

        const response = await renderPdf(params, id);

        if (Object.keys(response).length === 0) {
          return null;
        }

        const {
          report,
          contentType
          // fileName
        } = response;

        const blob = base64toBlob(report, contentType);
        const url = `${URL.createObjectURL(blob)}#view=fitV&toolbar=0&navpanes=0&scrollbar=0`;

        const file = new File([blob], 'report.pdf', { type: contentType });
        // setFormEditFiles(file);

        if (fileInputRef.current) {
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          fileInputRef.current.files = dataTransfer.files;
        }

        setReport(url);
      } catch (error) {
        setReport(null);
        toast.error('Error fetching document:', error.message);
      }
    };

    getReport();
  }, [navData]);

  const handleOnSubmit = async (e) => {
    console.log('e :>> ', e);
  };

  if (!report) {
    return null;
  }

  return (
    <WidgetProvider value={widgetContext}>
      {
        navData.widgetData.menu
          ? <MenuBar
            key={`menu-${navData.widgetData.menu.id}`}
            menu={navData.widgetData.menu}
          />
          : null
      }
      <ViewContainerStyled className='widget-container view'>
        <form id={`form-${navData.widgetData.key}`} onSubmit={handleOnSubmit}>
          <embed
            src={report}
            style={{ width: '100%', height: '100%' }}
          />
          <input
            type='file'
            ref={fileInputRef}
            disabled={true}
            style={{display: 'none'}}
          />
        </form>
      </ViewContainerStyled>

    </WidgetProvider>
  );
};

Report.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object,
};

export default Report;
