// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-upload-preview {
  background-color: rgb(255, 255, 255) !important;
  width: 95%;
  margin-top: 20px;
}

.e-file-select-wrap {
  background-color: rgb(246, 246, 246) !important;
  border: 2.2px dashed #ababab !important;
}

.e-btn {
  background-color: #c0c0c0 !important;
}

.e-dlg-header-content {
  height: 60px !important;
}

.e-upload-actions {
  display: none !important;
}

.uploader-button {
  height: 30px !important;
  font-size: 13px !important;
}

span#dropText {
  display: none !important;
  /* position: relative;
  left: 47%;
  top: 38px;
  color: gray !important; */
}

.page.dimmer,
.e-dlg-container {
  background-color: rgba(255, 255, 255, 0.25) !important;
  left: 0 !important;
  width: 100% !important;
}

.e-dlg-overlay {
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/widgets/upload/styles.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,+CAA+C;EAC/C,uCAAuC;AACzC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;EACxB;;;2BAGyB;AAC3B;;AAEA;;EAEE,sDAAsD;EACtD,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".dialog-upload-preview {\n  background-color: rgb(255, 255, 255) !important;\n  width: 95%;\n  margin-top: 20px;\n}\n\n.e-file-select-wrap {\n  background-color: rgb(246, 246, 246) !important;\n  border: 2.2px dashed #ababab !important;\n}\n\n.e-btn {\n  background-color: #c0c0c0 !important;\n}\n\n.e-dlg-header-content {\n  height: 60px !important;\n}\n\n.e-upload-actions {\n  display: none !important;\n}\n\n.uploader-button {\n  height: 30px !important;\n  font-size: 13px !important;\n}\n\nspan#dropText {\n  display: none !important;\n  /* position: relative;\n  left: 47%;\n  top: 38px;\n  color: gray !important; */\n}\n\n.page.dimmer,\n.e-dlg-container {\n  background-color: rgba(255, 255, 255, 0.25) !important;\n  left: 0 !important;\n  width: 100% !important;\n}\n\n.e-dlg-overlay {\n  background-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
