import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import ModuleNavigation from '../../components/modules/navigation/Modules.navigation';
import RichtextDialog from '../../widgets/form/fields/richtext/dialog/Dialog.richtext';
import UploaderWidget from '../../widgets/upload/Uploader';
import Loader from '../../components/Loader/Loader';
import WorkspacesMainstage from './workspaces/workspaces';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { WorkspaceContentContainerStyled } from './workspaces/styles';
import store from '../../store/store';
import { setNavigationOpen } from '../../store/navigation/actions';



const ModulePage = () => {
  const themes: any = useSelector((state: any) => state.getThemes);
  const modalStates = useSelector((state: any) => state.workspaces.instances.find(instance => instance.isActive)?.modals);
  const workspaces = useSelector((state: any) => state.workspaces);
  const navigation = useSelector((state: any) => state.navigation);

  const handleLeftSidebar = () => {
    store.dispatch(setNavigationOpen(!navigation.isOpen));
  };


  return (
    <>
      <div className="main-container" style={{ backgroundColor: themes?.data?.colors?.background?.hex }}>
        <div className={`navigation-container ${navigation.isOpen ? 'opened' : 'closed'}`}>
          {workspaces.instances.find(instance => instance.isActive)?.isPending ? <Loader /> : null}
          {workspaces.instances.map((workspace, index) => (
            <WorkspaceContentContainerStyled
              key={index}
              active={+workspace.isActive}
            >
              <ModuleNavigation workspace={workspace} isMenuOpen={navigation.isOpen} handleLeftSidebar={handleLeftSidebar.bind(this)} />
            </WorkspaceContentContainerStyled>
          ))}
        </div>
        <div className={`module-container ${navigation.isOpen ? 'navigation-container opened' : 'navigation-container closed'}`} style={{ height: '100%' }}>
          <Grid columns={1} style={{ height: '100vh', marginTop: '0rem' }}>
            <Grid.Column style={{ padding: 0, margin: 0, height: '100%' }}>
              <WorkspacesMainstage/>
            </Grid.Column>
          </Grid>
        </div>
        <ToastContainer />
        {modalStates?.imageDialog?.isOpen ? <RichtextDialog /> : null}
        {modalStates?.multiUpload?.isOpen ? <UploaderWidget /> : null}
      </div>
    </>
  );
};

export default ModulePage;