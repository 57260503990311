// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-reply-target-sender {
  font-weight: bold;
  color: rgb(181, 181, 181);
}

.message-reply-target-text {
  font-size: smaller;
}

.message-reply-target {
  width: 85%;
  margin-left: 12px;
  overflow-y: scroll;
  height: 80%;
}

.message-replay-separator {
  width: 2px;
  height: 50px;
  background-color: rgb(209, 209, 209);
}

.message-reply-container {
  display: flex !important;
  height: 80px;
  margin-bottom: 5px;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgb(247, 247, 247);
  border-radius: 10px;
}

.message-reply-close {
  width: 15%;
}

.message-reply-close-icon {
  margin-left: 30px;
  cursor: pointer !important;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/chat/body/message/reply/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,kCAAkC;EAClC,8BAA8B;EAC9B,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":[".message-reply-target-sender {\n  font-weight: bold;\n  color: rgb(181, 181, 181);\n}\n\n.message-reply-target-text {\n  font-size: smaller;\n}\n\n.message-reply-target {\n  width: 85%;\n  margin-left: 12px;\n  overflow-y: scroll;\n  height: 80%;\n}\n\n.message-replay-separator {\n  width: 2px;\n  height: 50px;\n  background-color: rgb(209, 209, 209);\n}\n\n.message-reply-container {\n  display: flex !important;\n  height: 80px;\n  margin-bottom: 5px;\n  justify-content: center !important;\n  align-items: center !important;\n  background-color: rgb(247, 247, 247);\n  border-radius: 10px;\n}\n\n.message-reply-close {\n  width: 15%;\n}\n\n.message-reply-close-icon {\n  margin-left: 30px;\n  cursor: pointer !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
