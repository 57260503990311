// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-tooltip-wrap.e-popup {
    background-color: #f8f8f8 !important;
    border: 1px solid #c7c7c7 !important;
}

.e-tooltip-wrap {
    border-radius: 4px !important;
    border-color: #c8c8c8 !important;
    opacity: 1 !important;
}

.e-tooltip-wrap .e-tip-content {
    color: rgb(71, 71, 71) !important;
    font-size: 12px !important;
    line-height: 20px !important;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-right,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-left,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {
    color: #f8f8f8 !important;
    font-size: 25.9px;
    border-color: #c7c7c7 !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
    border-top: 10px solid #c7c7c7 !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
    border-bottom: 10px solid #c7c7c7 !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {
    border-right: 10px solid #c7c7c7 !important;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-right {
    border-left: 10px solid #c7c7c7 !important;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/grid/stylesTooltip.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,gCAAgC;IAChC,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;IACjC,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;;;;IAII,yBAAyB;IACzB,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".e-tooltip-wrap.e-popup {\n    background-color: #f8f8f8 !important;\n    border: 1px solid #c7c7c7 !important;\n}\n\n.e-tooltip-wrap {\n    border-radius: 4px !important;\n    border-color: #c8c8c8 !important;\n    opacity: 1 !important;\n}\n\n.e-tooltip-wrap .e-tip-content {\n    color: rgb(71, 71, 71) !important;\n    font-size: 12px !important;\n    line-height: 20px !important;\n}\n\n.e-tooltip-wrap .e-arrow-tip-inner.e-tip-right,\n.e-tooltip-wrap .e-arrow-tip-inner.e-tip-left,\n.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom,\n.e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {\n    color: #f8f8f8 !important;\n    font-size: 25.9px;\n    border-color: #c7c7c7 !important;\n}\n\n.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {\n    border-top: 10px solid #c7c7c7 !important;\n}\n\n.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {\n    border-bottom: 10px solid #c7c7c7 !important;\n}\n\n.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {\n    border-right: 10px solid #c7c7c7 !important;\n}\n\n.e-tooltip-wrap .e-arrow-tip-outer.e-tip-right {\n    border-left: 10px solid #c7c7c7 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
