import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

interface KanbanContainerProps {
  columnwidth: number;
}
interface FilterWrapperProps {
  count: number;
}
interface FilterFieldProps {
  count: number;
}

interface CardCssProps {
  css: string;
}

export const KanbanContainerStyled = styled.div<KanbanContainerProps>`
  .e-kanban-header > div {
    width: 100% !important;
    padding-right: 10px !important;
  }

  .e-kanban-header .e-header-cells {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;    
  }

  .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells {
    border-top-left-radius: unset;
    border-top-right-radius: unset;    
  }
 
  .e-kanban-content {
    overflow: scroll !important;
  }

  /* .e-kanban-table,
  .e-kanban-table  > colgroup > col {
    width: ${(props) => props.columnwidth}px;
  } */
  
  .e-content-cells,
  .e-header-cells {
    width: ${(props) => props.columnwidth}px;
  }
  
  .e-content-cells {
    height: calc(100vh - 330px);
  }
  
  .e-header-table {
    margin-top: 10px !important;
  }

  .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card {
    padding: 8px;
  }

  .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card > .grid > .row > .column > .row{
    line-height: 27px;
  }

  .e-kanban .e-kanban-header .e-header-cells{
    height: 30px !important;
    border-top-left-radius: 8px; 
    border-top-right-radius: 8px;
  }
    
  .e-kanban .e-kanban-header .e-header-cells .e-header-text {
    font-size: 15px !important;
    text-transform: uppercase !important;
    font-weight: 550 !important;
    letter-spacing: -0.5px;
  }

  .e-card{
    box-shadow: 0 0 30px rgba(54, 54, 54, 0.1) !important;
    border-radius: 8px !important;
  }

  .e-collapse-header-text {
    display: none !important;
  }

  .e-toolbar .e-tbar-btn {
      color: var(--basic) !important;
  }

  .e-tbar-btn-text {
    color: var(--basic) !important;
    font-size: 13px !important;
  }

  .e-toolbar .e-tbar-btn:hover {
    background-color: #F5F5F5 !important;
    border-radius: 12px !important;
}

  .e-toolbar-items,
  .e-control,
  .e-toolbar-pop,
  .e-hor-nav {
    background-color: white !important;
  }
`;

// FIlter

export const KanbanFilterContainerStyled = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin: 5px 10px;
  `;

export const KanbanFieldStyled = styled.div`
  display: flex !important;
  width: 100%;
  font-size: 25px !important;
  border: 1px solid #E0E0E0 !important;
  border-radius: 5px !important;
  text-align: center !important;
  margin-top: 10px;

  .kanban-field-remove-line {
    border: none !important; 
    box-shadow: none !important;
    padding-left: 10px !important;
  }
  `;

export const LabelStyled = styled.label`
  font-weight: bold;
  display: inline-flex;
  height: 19px;
  width: 100%;
  color: rgba(0, 0, 0, 0.3) !important;
  font-weight: 400 !important;
`;

export const FilterWrapperStyled = styled.div<FilterWrapperProps>`
  display: flex;
  flex-direction: column;
  width: calc(calc(100% / ${props => props.count}) - 20px);
`;

export const FilterFieldContainerStyled = styled.div<FilterFieldProps>`
  display: flex !important;
  width: 100%;
  
  .ui.dropdown {
    position: absolute;
    margin-left: calc(calc(100% / ${props => props.count}) - 45px);
  }
  
  .ui.dropdown>.dropdown.icon {
    font-size: 20px;
    margin: 0px;
    padding: 7px 0 0 0;
    color: rgba(0, 0, 0, 0.54);
  }
  
  .ui.dropdown.active>.dropdown.icon:before {
    content: '\\f0d8';
  }

  .e-multi-select-wrapper .e-delim-values .e-remain {
    padding: 2px 0 0 0;
  }

  .e-multi-select-wrapper .e-chips {
    margin: 4px 0 0 0;
  }

  .e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon {
    padding-right: 24px;
  }

  .e-searcher,
  .e-dropdownbase,
  .e-multi-select-wrapper input[type=text] {
    height: 30px;
    min-height: 30px;
  }
  `;

export const ZoomControlsButtonContainerStyled = styled.div`
  display: flex;
  width: auto;
  padding: 10px 0 0 10px;
  & > button {
    margin-right: 10px;
  }
`;

export const GridStyled = styled(Grid)<CardCssProps>`
  ${props => props.css}
`;

export const Separator = styled.div`
    height: 15px !important;
    margin: 10px 6px !important; 
    font-size: 25px !important;
    font-weight: 50 !important; 
    color: lightgray ;
    `;

