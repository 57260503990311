import React from 'react';
import { withRouterHooks } from '../../routes/router.hooks';
import ContextList from './Context.list';
import PropTypes from 'prop-types';
import './styles.css';

const ContextWidget = ({ navData }) => {
  return (
    <ContextList
      elementList={navData.widgetData.elements}
      currentDataID={navData.widgetData.dataID || null}
      timestamp={navData.widgetData.timestamp}
    />
  );
};

ContextWidget.propTypes = {
  navData: PropTypes.object
};

export default withRouterHooks(ContextWidget);
