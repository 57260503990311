import React, { useState } from 'react';
import { marked } from 'marked';
import ChatReply from '../body/message/reply/Chat.message.reply';
import { sendPost } from '../../../api/services/chat/post.send';
import PropTypes from 'prop-types';
import './styles.css';
import { toast } from '../../../utils/notification';

const ChatFooter = ({ posts, isReply, setIsReply, replyTarget, workspaceActions }) => {
  const [text, setText] = useState('');

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (text === '') {
      return null;
    }

    const replacedText = text.replace(/\n/g, '<br>');

    try {
      const message = {
        id: workspaceActions.id,
        text: await marked(replacedText),
        answerTo: posts[posts.length - 1]?.id
      };
      setText('');
      await sendPost(message);
    } catch (error) {
      toast.error('Error sending message:', error.message);
      throw error;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    } else if (e.key === 'Enter' && e.shiftKey) {
      setText((prevState) => prevState + '\n');
    }
  };


  return (
    <div className='footer-container'>
      {isReply ?
        <ChatReply
          setIsReply={setIsReply}
          replyTarget={replyTarget}
        /> : null}
      <form
        className='footer-form'
      >
        <textarea
          placeholder='Write message'
          className='footer-message'
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button
          className={text !== '' ? 'footer-button' : 'footer-button-deactivated'}
          onClick={(e) => handleSendMessage(e)}
        >
          <span className='e-icons e-large e-send-1' />
        </button>
      </form>
    </div>
  );
};

ChatFooter.propTypes = {
  posts: PropTypes.array,
  isReply: PropTypes.bool,
  setIsReply: PropTypes.func,
  replyTarget: PropTypes.any,
  workspaceActions: PropTypes.any,
};
export default ChatFooter;
