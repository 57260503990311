import React, { useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import notAvailablePic from '../../../images/missing.png';
import { filePreview } from '../../../api/services/tools/service.filePreview';
import PropTypes from 'prop-types';

const ColumnImageComponent = ({ columns, row }) => {
  const [image, setImage] = useState(null);

  const path = row[columns[row.column.index].name];

  const matchFileType = (stringValue) => ({
    '.png': 'png',
    '.jpg': 'jpg',
    '.jpeg': 'jpeg',
    '.gif': 'gif'
  }[stringValue?.toLowerCase()?.match(/\.\w+$/)] || '');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await filePreview(path);

        if (!response || response.error) {
          setImage(notAvailablePic);
        } else {
          setImage('data:@file/' + (matchFileType(path)) + ';base64,' + response?.data);
        }
      } catch (error) {
        setImage(notAvailablePic);
      }
    };

    fetchData();
  }, []);

  if (!image) return;

  return (
    <Image
      src={image}
      size='mini'
    />
  );
};

ColumnImageComponent.propTypes = {
  columns: PropTypes.any,
  row: PropTypes.any
};

export default ColumnImageComponent;
