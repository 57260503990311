import { runStaticQuery } from 'src/api/query/run.staticQuery';
import request from 'src/request/request';

export  const actionComplete = async (args, kanbanData, navData, heartbeatUpdateData ) => {

  if(heartbeatUpdateData.data !== null) return;

  if (args.requestType === 'cardChanged') {
    args.changedRecords.map(async (record) => {
      const params = {
        nodeId: record[kanbanData.options.nodeId],
        stateId: record[kanbanData.options.keyField]
      };
      await runStaticQuery('StateMachine.SetState', params);
      await request<any, any>(false, 'POST', 'services/widgets/update-data', { data: { key: navData.widgetData.key, params: args.changedRecords[0], index: args.index } } );
    });
  }
};