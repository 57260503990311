import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PolicyButtonBar = ({ setDialogVisiblePolicy, setDialogContentPolicy, setDialogVisibleCookies, setDialogContentCookies }) => {
  const [buttons, setButtons] = useState(null);

  const handleButtonClick = (content, id, setDialogVisiblePolicy) => {

    if (id === 'cookies') {
      setDialogContentCookies(content);
      setDialogVisibleCookies(true);
      return;
    }
    setDialogContentPolicy(content);
    setDialogVisiblePolicy(true);
  };

  useEffect(() => {
    const privacyPolicy = JSON.parse(localStorage.getItem('privacyPolicy'));
    const legalNotice = JSON.parse(localStorage.getItem('legalNotice'));
    const cookieSettings = JSON.parse(localStorage.getItem('cookieSettings'));
    setButtons([privacyPolicy, legalNotice, { ...cookieSettings, id: 'cookies' }]);
  }, []);

  if (!buttons) return null;

  return (
    <div className='policy-button-container'>
      {buttons.map((button, index) => (
        <button
          key={index}
          className='policy-button'
          onClick={() => handleButtonClick(button.data, button.id, setDialogVisiblePolicy)}
          id={button.id}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
};

PolicyButtonBar.propTypes = {
  setDialogVisiblePolicy: PropTypes.func.isRequired,
  setDialogContentPolicy: PropTypes.func.isRequired,
  setDialogContentCookies: PropTypes.func.isRequired,
  dialogVisibleCookies: PropTypes.any,
  setDialogVisibleCookies: PropTypes.any,
  tyoe: PropTypes.string
};

export default PolicyButtonBar;
