// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  padding: 10px;
  background-color: #fffffe;
  height: 70px;
}

.footer-form {
  width: 100% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.footer-message {
  width: 85%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ddd;
  outline: none;
  padding: 15px;
  margin: 0 15px;
  white-space: pre-wrap !important;
  resize: none !important;
}

.footer-button,
.footer-button-deactivated {
  width: 100px;
  background-color: rgb(118, 205, 118);
  padding: 10px;
  border: none;
  outline: none;
  color: #fdfdfd;
  cursor: pointer;
  border-radius: 10px;
}

.footer-button-deactivated {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.footer-button:hover {
  background-color: rgb(97, 174, 97);
}
`, "",{"version":3,"sources":["webpack://./src/widgets/chat/footer/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,cAAc;EACd,gCAAgC;EAChC,uBAAuB;AACzB;;AAEA;;EAEE,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,YAAY;EACZ,aAAa;EACb,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".footer-container {\n  padding: 10px;\n  background-color: #fffffe;\n  height: 70px;\n}\n\n.footer-form {\n  width: 100% !important;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between !important;\n}\n\n.footer-message {\n  width: 85%;\n  height: 100%;\n  border-radius: 10px;\n  border: 1px solid #ddd;\n  outline: none;\n  padding: 15px;\n  margin: 0 15px;\n  white-space: pre-wrap !important;\n  resize: none !important;\n}\n\n.footer-button,\n.footer-button-deactivated {\n  width: 100px;\n  background-color: rgb(118, 205, 118);\n  padding: 10px;\n  border: none;\n  outline: none;\n  color: #fdfdfd;\n  cursor: pointer;\n  border-radius: 10px;\n}\n\n.footer-button-deactivated {\n  opacity: 0.5 !important;\n  pointer-events: none !important;\n}\n\n.footer-button:hover {\n  background-color: rgb(97, 174, 97);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
