import {
  SET_HEART_UPDATE_WIDGET_DATA
} from './types';
import initialState from './initialState';

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_HEART_UPDATE_WIDGET_DATA:
      return {
        ...state,
        data: action.data
      };

    default:
      return state;
  }
};

export default reducer;
