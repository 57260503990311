import React from 'react';
import PropTypes from 'prop-types';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { cookieDeclineToast } from './cookie.Decline.toast';
import { logout } from '../../../api/functions/func/function.logout';
import DOMPurify from 'dompurify';

const CookieConsentComponent = ({ 
  dialogVisibleCookies, 
  setDialogVisibleCookies, 
  dialogContentCookies,
  type
}) => {


  const handleDecline = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(false));
    setDialogVisibleCookies(false);
    cookieDeclineToast();
    if(type === 'system'){
      setTimeout(logout, 4000);
    }
  };

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', JSON.stringify(true));
    setDialogVisibleCookies(false);
  };

  const buttons = [
    {
      click: handleDecline,
      buttonModel: { content: navigator.language === 'de-DE' ? 'Ablehnen' : 'Decline', cssClass: 'e-decline-button' }
    },
    {
      click: handleAccept,
      buttonModel: { content: navigator.language === 'de-DE' ? 'Alles akzeptieren' : 'Accept all', cssClass: 'e-accept-button' }
    }
  ];

  if (!dialogContentCookies || !dialogVisibleCookies) return null;

  return (
    <DialogComponent
      visible={dialogVisibleCookies}
      closeOnEscape={false}
      showCloseIcon={true}
      isModal={true}
      width="30%"
      height="50%"
      content={() => (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialogContentCookies) }} />
      )}
      buttons={buttons}
      overlayClick={() => setDialogVisibleCookies(false)}
      open={() => setDialogVisibleCookies(true)}
      close={() => setDialogVisibleCookies(false)}
    />
  );
};

CookieConsentComponent.propTypes = {
  dialogVisibleCookies: PropTypes.bool.isRequired,
  setDialogVisibleCookies: PropTypes.func.isRequired,
  dialogContentCookies: PropTypes.string,
  type: PropTypes.string
};

export default CookieConsentComponent;
