import React, { useState, useEffect, useRef } from 'react';
import Card from './Gallery.card';
import { run as runActions } from '../../store/workspaces/workspaces/run';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import PropTypes from 'prop-types';
import { toast } from '../../utils/notification';
import './styles.css';

const GalleryWidget = ({ navData, actionsState }) => {
  const [imageData, setImageData] = useState(null);
  const targetDivRef = useRef(null);
  const [number, setNumber] = useState(50);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await runDynamicQuery({ widgetKey: navData.widgetData.key, ...actionsState }, navData.widgetData);
        setImageData(response.targets);
      } catch (error) {
        toast.error('Error fetching gallery data:', error.message);
      }
    };
    if (navData && actionsState?.data) {
      fetchData();
    }
  }, [navData.widgetData.dataID, actionsState.key, actionsState.timestamp ]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = targetDivRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 60) {
        setNumber(prev => prev + 10);
      }
    };
  
    const divRef = targetDivRef.current;
  
    if (divRef) {
      divRef.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (divRef) {
        divRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [actionsState, imageData, number]);

  const handleClick = async (imageID) => {
    runActions(navData.widgetData.events.find((event) => event.type === 'onClick').key, imageID, actionsState);
  };

  const setCounter = () => `(${imageData.length} ${imageData.length === 1 ? 'item' : 'items'})`;

  if (!imageData) {
    return null;
  }


  return (
    <>
      <div className="gallery" ref={targetDivRef}>
        <div className="gallery-container wrap" >
          {imageData.slice(0, number).map((image) => (
            <div key={`gallery-card-wrapper-${image.id}`} className="gallery-item" onClick={() => handleClick(image.id)}>
              <Card
                key={`gallery-item-${image.id}`}
                absolutePath={image.absolutePath}
                imageName={image.name}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='gallery-counter'>
        {setCounter()}
      </div>
    </>
  );
};

GalleryWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default GalleryWidget;
