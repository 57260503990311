import { runStaticQuery } from '../../../../../../../api/query/run.staticQuery';


export const getData = async (setGridData, params, tabType) => {



  runStaticQuery(
    tabType === 'existing' ? 'existingImage' : 'imagesQuery',
    { ...params}
  )
    .then((response) => {
      console.log(
        `%c StoredProcedure (${tabType === 'existing' ? 'getExistingImages' : 'getImages'}) %c`,
        'background: #F9E79F; color: #000',
        'background: transparent; color: black;',
        {
          params: params,
          response: response
        }
      );
      const arr = Object.values(response.elements);
      const results = arr.filter(element => {
        return element !== null;
      });
      return setGridData(results);

    });
};