import { runDynamicQuery } from '../../../api/query/run.dynamicQuery';
import { toast } from '../../../utils/notification';

export const setGridData = async (setGridDataState, navData, actionsState) => {
  if (actionsState) {
    try {
      const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.widgetData.key }, navData.widgetData);
      setGridDataState(response.targets);
    } catch (error) {
      toast.error('Error fetching grid data:', error.message);
      throw error;
    }
  }
};