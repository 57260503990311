import React, { useEffect, useState } from 'react';
import FormWidget from '../../widgets/form/Form';
import GraphWidget from '../../widgets/graph/Graph';
import TreeGridWidget from '../../widgets/treegrid/Treegrid';
import ContextWidget from '../../widgets/context/Context';
import GalleryWidget from '../../widgets/gallery/Gallery';
import GanttWidget from '../../widgets/gantt/Gantt';
import ModalDialog from '../../components/modals/Modal.dialog';
import View from '../../widgets/View/text/View.text';
import DiagramWidget from '../../widgets/diagram/Diagram';
import GridWidgetView from '../../widgets/grid/view/Grid.view';
import GridWidgetSort from '../../widgets/grid/sort/Grid.sort';
import GridWidgetLink from '../../widgets/grid/link/Grid.link';
import GridWidgetUpdate from '../../widgets/grid/update/Grid.update';
import ChatWidget from '../../widgets/chat/Chat';
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary';
import MapWidget from '../../widgets/map/Map';
import Scheduler from '../../widgets/scheduler/Scheduler';
// import FileViewer from '../../widgets/View/files/View.files';
import Report from '../../widgets/report/Report';
import CarouselWidget from '../../widgets/gallery/Gallery.carousel';
import KanbanComponent from '../../widgets/kanban/Kanban';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CadViewer from '../../widgets/View/cad/View.cad';
import PDFViewer from '../../widgets/View/pdf/View.pdf';
import ChartWidget from '../../widgets/chart/Chart';

const LayoutWidget1 = ({ widget, workspaceActions }) => {
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    setCurrentPage({
      label: widget.label,
      widget: widget.widget,
      renderID: widget.key,
      dataID: null,
      params: { 'source': { 'id': null }, 'relation': null, 'target': { 'type': null } },
      widgetData: widget,
      query: widget.query
    });
  }, [widget, workspaceActions]);


  const commonProps = {
    actionsState: workspaceActions,
    navData: currentPage,
  };


  if (!currentPage) return null;


  switch (currentPage.widget) {
    case 'Chat':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <ChatWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Chart':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <ChartWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Context':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <ContextWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Diagram':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <DiagramWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Form':
      if (currentPage.widgetData.type === 'dialog') {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <ModalDialog {...commonProps} />
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <FormWidget {...commonProps} />
          </ErrorBoundary>
        );
      }

    case 'Gallery':
      if (currentPage.widgetData?.type === 'carousel') {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <CarouselWidget {...commonProps} />
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
            <GalleryWidget {...commonProps} />
          </ErrorBoundary>
        );
      }

    case 'Gantt':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <GanttWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Graph':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <GraphWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Grid':
      switch (currentPage.widgetData?.type) {
        case 'view':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <GridWidgetView {...commonProps} />
            </ErrorBoundary>
          );
        case 'link':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <GridWidgetLink {...commonProps} />
            </ErrorBoundary>
          );
        case 'sort':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <GridWidgetSort {...commonProps} />
            </ErrorBoundary>
          );
        case 'update':
        case 'edit':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <GridWidgetUpdate {...commonProps} />
            </ErrorBoundary>
          );
        default:
          return null;
      }

    case 'Kanban':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <KanbanComponent
            actionsState={workspaceActions}
            navData={currentPage}
          />
        </ErrorBoundary>
      );

    case 'Map':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <MapWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'Scheduler':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <Scheduler {...commonProps} />
        </ErrorBoundary>
      );

    case 'TreeGrid':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <TreeGridWidget {...commonProps} />
        </ErrorBoundary>
      );

    case 'View':
      switch (currentPage.widgetData?.format) {
        case 'text':
        case 'html':
        case 'markdown':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <View {...commonProps} />
            </ErrorBoundary>
          );
        case 'file':
          // if(workspaceActions.fileType === 'PDF' || workspaceActions.fileType === 'pdf'){
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <PDFViewer
                actionsState={workspaceActions}
                navData={currentPage}
              />
            </ErrorBoundary>
          );
          // }else{
          //   return (
          //     <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          //       <FileViewer
          //         actionsState={workspaceActions}
          //         navData={currentPage}
          //       />
          //     </ErrorBoundary>
          //   );
          // }
        case 'cad':
          return (
            <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
              <CadViewer
                actionsState={workspaceActions}
                navData={currentPage}
              />
            </ErrorBoundary>
          );
        default:
          return null;
      }
    case 'Report':
      return (
        <ErrorBoundary currentPage={currentPage} key={currentPage.renderID}>
          <Report
            actionsState={workspaceActions}
            navData={currentPage}
          />
        </ErrorBoundary>
      );

    default:
      return null;
  }
};

LayoutWidget1.propTypes = {
  widget: PropTypes.object,
  workspaceActions: PropTypes.object
};


export default connect()(LayoutWidget1);
