import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  Zoom,
  Crosshair,
  WaterfallSeries,
  LineSeries,
  StepLineSeries,
  StackingLineSeries,
  SplineSeries,
  RangeStepAreaSeries,
  RangeAreaSeries,
  AreaSeries,
  SplineRangeAreaSeries,
  StackingAreaSeries,
  StackingStepAreaSeries,
  StepAreaSeries,
  ColumnSeries,
  SplineAreaSeries,
  RangeColumnSeries,
  BarSeries,
  BoxAndWhiskerSeries,
  BubbleSeries,
  CandleSeries,
  HiloSeries,
  HistogramSeries,
  ParetoSeries,
  PolarSeries,
  RadarSeries,
  ScatterSeries,
  StackingBarSeries,
  StackingColumnSeries
} from '@syncfusion/ej2-react-charts';
import { WidgetContainerStyled, WidgetContentStyled } from '../styles';
import { WidgetProvider } from '../WidgetContext';
import MenuBar from '../../components/MenuBar/MenuBar';
import { ChartContainerStyled } from './styles';
import { runDynamicQuery } from '../../api/query/run.dynamicQuery';
import { toast } from '../../utils/notification';
import { useSelector } from 'react-redux';
import { run as runActions } from '../../store/workspaces/workspaces/run';

const ChartWidget = ({ navData, actionsState }) => {
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [currentWorkspaceUuid, seCurrentWorkspaceUuid] = useState(null);
  const workspaces = useSelector((state: any) => state.workspaces.instances);
  const { widgetData } = navData;

  const widgetContext: any = {
    widgetData: widgetData,
    viewInstance: chartInstance.current,
    actionsState: actionsState,
    currentWorkspaceUuid: currentWorkspaceUuid
  };

  useEffect(() => {
    const currentWorkspace = workspaces?.find((w) => w.isActive);
    seCurrentWorkspaceUuid(currentWorkspace.uuid);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {      
        const response = await runDynamicQuery({ ...actionsState, widgetKey: navData.renderID }, widgetData);
        setChartData(response);
      } catch (error) {
        toast.error('Error fetching Gantt data:', error.message);
      }
    };
    fetchData();
  }, [navData.widgetData.dataID, navData.widgetData.timestamp]);


  const pointClick = (args) => {
    const eventKey = widgetData.events.find(event => event.type === 'onClick')?.key;
    const id = chartData[chartData.options.datasource][args.pointIndex]?.id;
  
    if (!eventKey) {
      return toast.warning('Event is missing');
    }

    id && runActions(eventKey, id, actionsState);
  };
  
  
  if(!chartData) return;


  return (
    <WidgetContainerStyled>
      <WidgetProvider value={widgetContext}>
        {widgetData.menu && (
          <MenuBar
            key={`menu-${widgetData.menu.id}`}
            menu={widgetData.menu}
          />
        )}
        <WidgetContentStyled>
          <ChartContainerStyled>
            <ChartComponent
              ref={(g) => (chartInstance.current = g)}
              height='100%'
              id={widgetData.dataID}
              primaryXAxis={widgetData.primaryXAxis}
              primaryYAxis={widgetData.primaryYAxis}
              tooltip={widgetData.tooltip}
              legendSettings={widgetData.legendSettings}
              title={widgetData.title}
              pointClick={pointClick}
            >
              <Inject
                services={[
                  WaterfallSeries, 
                  Category, 
                  Tooltip, 
                  Zoom, 
                  Crosshair, 
                  Legend, 
                  DataLabel, 
                  LineSeries, 
                  StepLineSeries, 
                  StackingLineSeries, 
                  SplineSeries, 
                  AreaSeries, 
                  RangeAreaSeries, 
                  RangeStepAreaSeries, 
                  SplineRangeAreaSeries, 
                  StackingAreaSeries, 
                  StackingStepAreaSeries, 
                  StepAreaSeries, 
                  SplineAreaSeries, 
                  ColumnSeries, 
                  RangeColumnSeries, 
                  StackingColumnSeries, 
                  BarSeries, 
                  StackingBarSeries, 
                  ScatterSeries, 
                  BubbleSeries, 
                  PolarSeries, 
                  RadarSeries, 
                  HiloSeries, 
                  CandleSeries, 
                  BoxAndWhiskerSeries, 
                  HistogramSeries, 
                  ParetoSeries
                ]}
              />
              <SeriesCollectionDirective>
                {
                  widgetData.series.map((serie, index) => {
                    return (
                      <SeriesDirective
                        key={index} 
                        dataSource={chartData[chartData.options.datasource]}
                        xName={serie.xName}
                        yName={serie.yName}
                        name={serie.name}
                        type={serie.type}
                        pointColorMapping={serie.pointColorMapping}
                      />
                    );
                  })
                }
              </SeriesCollectionDirective>
            </ChartComponent>
          </ChartContainerStyled>
        </WidgetContentStyled>
      </WidgetProvider>
    </WidgetContainerStyled>
  );
};

ChartWidget.propTypes = {
  navData: PropTypes.object,
  actionsState: PropTypes.object
};

export default ChartWidget;